import { cloneDeep } from '@gonfalon/es6-utils';

import { BlockData, FunnelsConfiguration } from '../../types/types';

export const warehouseIntegrationsQueryKeys = {
  all: ['warehouse-integrations'],
  byProjectAndEnvironment: ({ projectKey, environmentKey }: { projectKey: string; environmentKey: string }) => [
    ...warehouseIntegrationsQueryKeys.all,
    projectKey,
    environmentKey,
  ],
};

export const blocksQueryKeys = {
  all: ['blocks'],
  recents: () => ['recent-blocks'],
  byId: ({ id }: { id: string }) => [...blocksQueryKeys.all, id] as const,
};

function getCommonConfigKeyFromBlockForPAVizs(block: BlockData<'visualization'>) {
  return [
    JSON.stringify(block?.configuration?.dateFilter ?? {}),
    JSON.stringify(block?.configuration?.compare ?? {}),
    JSON.stringify(block?.configuration?.meta ?? {}),
  ];
}

function getCommonConfigKeyFromAppForPAVizs(appConfig: BlockData<'app'>['configuration']) {
  return [JSON.stringify(appConfig?.cohort_filters ?? {}), JSON.stringify(appConfig?.dateFilter ?? {})];
}

export const visualisationRootQueryKey = ['visualisation'];

export const flowsQueryKeys = {
  all: [...visualisationRootQueryKey, 'flows'],
  byBlockData: ({ blockData, appData }: { blockData: BlockData<'visualization'>; appData: BlockData<'app'> }) => [
    ...flowsQueryKeys.all,
    ...getCommonConfigKeyFromBlockForPAVizs(blockData),
    JSON.stringify(appData?.configuration?.dateFilter ?? {}),
    JSON.stringify({
      representation: blockData?.properties?.representation,
      configuration: Object.keys(blockData?.configuration.user_flow || {})
        .sort()
        .reduce((obj: any, key: string) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          obj[key] = (blockData?.configuration.user_flow || {})[key];
          return obj;
        }, {}),
    }),
  ],
};

export const insightsQueryKeys = {
  all: [...visualisationRootQueryKey, 'insights'],
  byBlockData: ({
    blockData,
    appConfig,
  }: {
    blockData: BlockData<'visualization'>;
    appConfig: BlockData<'app'>['configuration'];
  }) => [
    ...insightsQueryKeys.all,
    ...getCommonConfigKeyFromBlockForPAVizs(blockData),
    ...getCommonConfigKeyFromAppForPAVizs(appConfig),
    JSON.stringify({
      config: Object.keys(blockData?.configuration.insights || {})
        .sort()
        .reduce((obj: any, key: string) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          obj[key] = (blockData?.configuration.insights || {})[key];
          return obj;
        }, {}),
    }),
    blockData.properties.representation,
  ],
};

export const funnelsQueryKeys = {
  all: [...visualisationRootQueryKey, 'funnels'],
  byBlockData: ({
    blockData,
    appConfig,
  }: {
    blockData: BlockData<'visualization'>;
    appConfig: BlockData<'app'>['configuration'];
  }) => {
    const funnelConfig = cloneDeep(blockData.configuration.funnels || ({} as FunnelsConfiguration));
    if (funnelConfig?.view_config) {
      if (funnelConfig?.view_config?.from_event) {
        if (funnelConfig.view_config.from_event_index === 0) {
          // delete properties stored for backward compatibility
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          delete funnelConfig.view_config.from_event_index;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          delete funnelConfig.view_config.target_event_index;
        }
      } else if (funnelConfig?.view_config !== undefined) {
        delete funnelConfig.view_config.from_event;
        delete funnelConfig.view_config.target_event;
      }
    }

    if (funnelConfig?.time_buckets) {
      if (funnelConfig?.time_buckets?.from_event) {
        if (funnelConfig.time_buckets.from_event_index === 0) {
          // delete properties stored for backward compatibility
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          delete funnelConfig.time_buckets.from_event_index;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          delete funnelConfig.time_buckets.target_event_index;
        }
      } else if (funnelConfig?.time_buckets !== undefined) {
        delete funnelConfig.time_buckets.from_event;
        delete funnelConfig.time_buckets.target_event;
      }
    }

    return [
      ...flowsQueryKeys.all,
      ...getCommonConfigKeyFromBlockForPAVizs(blockData),
      ...getCommonConfigKeyFromAppForPAVizs(appConfig),

      JSON.stringify({
        config: Object.keys(funnelConfig)
          .sort()
          .reduce((obj: any, key: string) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            obj[key] = funnelConfig[key];
            return obj;
          }, {}),
      }),
      blockData.properties.representation,
    ];
  },
};

export const retentionQueryKeys = {
  all: [...visualisationRootQueryKey, 'retention'],
  byBlockData: ({
    blockData,
    appConfig,
  }: {
    blockData: BlockData<'visualization'>;
    appConfig: BlockData<'app'>['configuration'];
  }) => [
    ...retentionQueryKeys.all,
    ...getCommonConfigKeyFromBlockForPAVizs(blockData),
    ...getCommonConfigKeyFromAppForPAVizs(appConfig),
    JSON.stringify({
      config: Object.keys(blockData?.configuration.retention_viz || {})
        .sort()
        .reduce((obj: any, key: string) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          obj[key] = (blockData?.configuration.retention_viz || {})[key];
          return obj;
        }, {}),
    }),
    blockData.properties.representation,
  ],
};

export const stickinessQueryKeys = {
  all: [...visualisationRootQueryKey, 'stickiness'],
  byBlockData: ({
    blockData,
    appConfig,
  }: {
    blockData: BlockData<'visualization'>;
    appConfig: BlockData<'app'>['configuration'];
  }) => [
    ...stickinessQueryKeys.all,
    ...getCommonConfigKeyFromBlockForPAVizs(blockData),
    ...getCommonConfigKeyFromAppForPAVizs(appConfig),
    JSON.stringify({
      config: Object.keys(blockData?.configuration.stickiness_viz || {})
        .sort()
        .reduce((obj: any, key: string) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          obj[key] = (blockData?.configuration.stickiness_viz || {})[key];
          return obj;
        }, {}),
    }),
    blockData.properties.representation,
  ],
};

export const eventsQueryKeys = {
  all: () => ['events'],
  customEvents: () => [...eventsQueryKeys.all(), 'custom_events'],
  hiddenEvents: () => [...eventsQueryKeys.all(), 'hidden_events'],
};

export const eventMetadataQueryKeys = {
  all: ['event-metadata'],
  byEventName: ({ eventName }: { eventName: string }) => [...eventMetadataQueryKeys.all, eventName] as const,
};

export const organizationUsersQueryKeys = {
  byId: ({ id }: { id: string }) => ['organization', id, 'users'] as const,
};

export const cohortsQueryKey = {
  all: () => ['cohorts_'],
  byId: (id: string) => [cohortsQueryKey.all(), id],
};

export const userLookupQueryKeys = {
  all: () => ['user-lookup'],
  byPayload: (payload: unknown) => [userLookupQueryKeys.all(), JSON.stringify(payload)],
};
